import { Exclamation } from 'components/Icons';
import { DraftMessageContainer, DraftText } from './style';

interface Props {
  surveyInProgress?: boolean;
}

export const DraftMessage = ({
  surveyInProgress = true,
}: Props) => {
  return (
    <DraftMessageContainer surveyInProgress={surveyInProgress}>
      <Exclamation className="exclamation-icon" />
      <DraftText>Este formulário é apenas para visualização e não registra respostas.</DraftText>
    </DraftMessageContainer>
  );
};
