import { Relogio } from 'components/Icons';
import styled from 'styled-components';

export const TimeWrapper = styled.div`
`;

export const StyledClockIcon = styled(Relogio)`
  fill: ${({ theme }) => theme.colors.gray400};
  stroke: ${({ theme }) => theme.colors.gray400};
`;

export const StyledLabel = styled.label`
  margin-bottom: 8px;
  display: block;
  color: ${({ theme }) => theme.colors.gray400};
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TimeInputContainer = styled.div`
  max-width: 250px;
  height: 48px;
  background: transparent;
  display: flex;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};

  & .clock-icon-wrapper {
    align-self: center;
    justify-content: center;

    & .clock-icon svg {
      width: 18px;
      stroke: ${({ theme }) => theme.colors.gray400};
    }

    cursor: pointer;
  }

  @media only screen and (max-width: 460px) {
    max-width: 100%;
  }
`;

export const TimeInput = styled.input`
  width: calc(100% - 32px);
  height: 100%;
  padding: 16px;
  background: inherit;
  outline: none;

  color: ${({ theme }) => theme.colors.gray500};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Open Sans";

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray300};
  }
`;

export const TimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 208px;
  padding: 16px;
  gap: 16px;

  position: absolute;
  left: 50px;
  margin-top: 8px;

  background: ${({ theme }) => theme.colors.white};

  @media only screen and (max-width: 460px) {
    left: auto;
    top: -86px;
    z-index: 1001;
  }

  & .datepicker-container-header {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray400};
    gap: 8.5px;
  }

  & .buttons-container {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: auto;
    justify-content: flex-end;
    gap: 8px;

    .confirm-button {
      font-family: "Open Sans";
      font-size: 12px;
      height: 24px;
      width: 67px;
      border-radius: 4px;
      font-weight: 400;
      padding-left: 6.5px;
      padding-right: 6.5px;
      margin-top: 5px;
      color: ${({ theme }) => theme.colors.gray400};
      border: 1px solid ${({ theme }) => theme.colors.primary};
      outline: none;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
      }
    }

    .clean-button {
      font-family: "Open Sans";
      font-weight: 400;
      height: 24px;
      font-size: 12px;
      border: none;
      padding: 2px 8px;
      align-self: center;
      color: ${({ theme }) => theme.colors.gray400};
      margin-top: 5px;
      outline: none;

      &:hover {
        color: ${({ theme }) => theme.colors.gray500};
      }
    }
  }

  border: 1px solid
    ${({ theme }) => theme.colors.gray200};

  color: ${({ theme }) => theme.colors.gray500};
  font-size: 12px;
  font-family: "Open Sans";
`;

export const TimeSelectorContainer = styled.div`
  display: flex;
  justify-content: center;

  .time-selector-separator {
    width: 22px;
    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 9.56px;
    align-items: center;

    & span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.gray400};
    }
  }

`;

export const TimeSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    line-height: 22px;
  }
`;

export const TimeSpan = styled.span`
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.gray400};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;

export const TimeSelector = styled.input`
  max-width: 80px;
  height: 32px;
  background: ${({ theme }) => theme.colors.gray100};
  border-radius: 4px;
  outline: none;
  padding: 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.gray500};
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
`;

export const TimeSelect = styled.div`
  max-width: 87.11px;
  margin-top: 7px;
  padding: 8px;
  overflow-y: scroll;
  max-height: 152px;
  overflow: auto;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.gray300};
  background: ${({ theme }) => theme.colors.gray100};

  option {
    cursor: pointer;
    font-size: 12px;
    
    :hover {
      color: ${({ theme }) => theme.colors.gray500};
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    margin: 10px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray300};
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /** mobile */
  @media only screen and (max-width: 600px) {
    max-height: 105px;
  }
`;
