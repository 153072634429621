import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #root {
    height: 100vh;
  }

  iframe {
    display: none;
  }

  body { 
    font-family: 'Open Sans', sans-serif;
    background-color: ${({ theme }) => theme.colors.gray100};
  }

`;
