import styled from 'styled-components';

interface ScrollProp {
  scrollMarginX: boolean;
  scrollMarginY: boolean;
}

interface IDraftProp {
  isDraft: boolean;
}

export const Container = styled.div<IDraftProp>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  height: 100%;
  position: relative;
  height: ${props => props.isDraft ? 'calc(100% - 460px)' : 'calc(100% - 400px)'}; 

  @media only screen and (max-width: 460px) {
    height: ${props => props.isDraft ? 'calc(100% - 365px)' : 'calc(100% - 300px)'};
  }

`;

export const ContainerWrapper = styled.section <IDraftProp>`
  display: flex;
  flex-direction: column;
  height: ${props => props.isDraft ? 'calc(100% - 28px)' : '100%'};
  justify-content: space-between;

  @media only screen and (max-width: 460px) {
    height: 100%;
  }

  .isRender {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Content = styled.div<ScrollProp>`
  max-height: 100%;
  height: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 0;
    height: 10px;
    transform: translate(-20px);
  }
  ::-webkit-scrollbar-track {
      background: transparent;
  }
  ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.scrollBar};
  }

  ::-webkit-scrollbar-corner {
    background: none;
  }

  @media only screen and (min-width: 460px) {

    ::-webkit-scrollbar {
      width: 50px;
      height: 15px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px 0 10px 0;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.scrollBar};
    }
  }
`;

export const SubContent = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 10px 10px;
  height: auto;

  @media only screen and (max-width: 460px) {
    padding: 16px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  .goBackBtn {
    background-color: ${({ theme }) => theme.colors.gray100};
    color: ${({ theme }) => theme.colors.gray400};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  @media only screen and (max-width: 767px) {
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background-color: ${({ theme }) => theme.colors.buttonsContainer};
    padding: 12px;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 8px;
  }
`;
