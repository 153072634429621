import styled from 'styled-components';

interface IDraftProp {
  surveyInProgress: boolean;
}

export const DraftMessageContainer = styled.div<IDraftProp>`
  display: flex;
  width: ${props => props.surveyInProgress ? '100%' : 'calc(100% - 64px)'};
  height: 48px;
  padding: 8px 16px;
  align-items: center;
  align-self: center;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.error100};
  margin-bottom: 20px;
  gap: 8px;

  .exclamation-icon {
    min-width: 16px;
    min-height: 16px;
  }

  @media only screen and (max-width: 460px) {
    width: 100%;

    .exclamation-icon {
      min-width: 14px;
      min-height: 14px;
    }
  }
  
`;

export const DraftText = styled.p`
  color: ${props => props.theme.colors.gray400};
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 460px) {
    font-size: 12px;
  }
`;
