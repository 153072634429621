import styled from 'styled-components';

export const Container = styled.div`
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 40px;
    transform: translate(-20px);
  }
  ::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px 0 10px 0;
  }
  ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.scrollBar};
  }

  ::-webkit-scrollbar-corner {
    background: none;
  }

  @media only screen and (min-width: 460px) {
    ::-webkit-scrollbar {
      width: 40px;
      height: 70px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      margin: 10px -30px 10px -30px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.scrollBar};
      border: 13px solid ${({ theme }) => theme.colors.gray100};
    }
  }
`;
